"use client"
import { useEffect, useRef, useState } from "react"

export const useMediaQuery = (query, defValue: boolean = false, brakeHydration?: boolean) => {
    const [_, setUpdate] = useState(0)
    const matchRef = useRef(typeof window !== "undefined" && brakeHydration ? window.matchMedia(query).matches : defValue)
    const mqRef = useRef<MediaQueryList>()
    if (!mqRef.current && typeof window !== "undefined") {
        mqRef.current = window.matchMedia(query)
        matchRef.current = mqRef.current.matches
    }
    useEffect(() => {
        mqRef.current = window.matchMedia(query)
        const handler = () => {
            matchRef.current = mqRef.current.matches
            setUpdate(Date.now())
        }
        if (mqRef.current.addEventListener) {
            mqRef.current.addEventListener("change", handler)
        } else {
            mqRef.current.addListener(handler)
        }
        handler()
        return () => {
            if (mqRef.current.removeEventListener) {
                mqRef.current.removeEventListener("change", handler)
            } else {
                mqRef.current.removeListener(handler)
            }
        }
    }, [query])
    if (typeof window === "undefined") {
        return defValue
    }
    return matchRef.current
}
